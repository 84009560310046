// src/pages/Projects.tsx
import React from "react";

const allProjects = [
  {
    id: 1,
    title: "Game Design @ IndieCade",
    description: "Unity, C#.",
    link: "",
    color: "bg-purple-400", // Experience
  },
  {
    id: 2,
    title: "Full-Stack Web Dev @ Polyglot",
    description: "TypeScript, React, NodeJS, Tailwind CSS",
    link: "",
    color: "bg-purple-400", // Experience
  },
  {
    id: 3,
    title: "HellBound",
    description: "A biopunk FPS game. Unity, C#, Blender, FLStudio",
    link: "",
    color: "bg-red-400", // Game
  },
  {
    id: 4,
    title: "VR Escape Room Maker",
    description:
      "Mario Maker but for Escape Rooms. Unity, XR Interaction Toolkit, Meta Quest 3, C#.",
    link: "",
    color: "bg-blue-400", // XR
  },
  {
    id: 5,
    title: "CruzMaps",
    description:
      "A more modernized redesign of UCSC's map. MapBox GL JS. React, Next.js, TailwindCSS, Typescript",
    link: "",
    color: "bg-green-400", // CS
  },
  {
    id: 6,
    title: "Selfcare.io",
    description:
      "App to help users identify their skin issues using an SVM. React, Flask, Scikit-learn",
    link: "",
    color: "bg-yellow-400", // ML/AI
  },
  {
    id: 7,
    title: "Micro Party Games",
    description: "A collection of micro games. Unity, C#",
    link: "",
    color: "bg-red-400", // Game
  },
  {
    id: 8,
    title: "Purrfect Match",
    description:
      "CalHacks Hack for Impact Project - A tinder-like app for matching cat lovers to homeless cats. Flutter, Firebase",
    link: "",
    color: "bg-green-400", // CS
  },
  {
    id: 9,
    title: "MR City Builder",
    description: "Unity, XR Interaction Toolkit, Meta Quest 3, C#.",
    link: "",
    color: "bg-blue-400", // XR
  },
  {
    id: 10,
    title: "Coming soon...",
    description: "More updates and projects in the works!",
    link: "",
    color: "bg-gray-400", // In Prog
  },
];

const Projects: React.FC = () => {
  return (
    <section className="min-h-screen bg-gray-100 py-10">
      <div className="max-w-screen-lg mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {allProjects.map((project) => (
          <a
            key={project.id}
            href={project.link || "#"}
            target="_blank"
            rel="noopener noreferrer"
            className={`${project.color} text-white shadow-lg rounded-lg p-4 transition-transform transform hover:scale-105 cursor-pointer`}
          >
            <h3 className="text-xl font-semibold">{project.title}</h3>
            <p className="mt-1">{project.description}</p>
          </a>
        ))}
      </div>
    </section>
  );
};

export default Projects;
