// src/pages/AboutMe.tsx
import React from "react";

const AboutMe: React.FC = () => {
  return (
    <section
      id="about-me"
      className="py-16 px-4 bg-gray-100 text-gray-800 text-center"
    >
      <h2 className="text-3xl md:text-4xl font-bold mb-6">About Me</h2>
      <p className="max-w-xl mx-auto text-base md:text-lg leading-relaxed">
        Hello! I'm Tommy Nguyen, a passionate Game Designer and Software
        Engineer. I have a strong background in both game design principles and
        software development, allowing me to craft engaging experiences from
        both a creative and technical perspective. I love working on games,
        solving problems, and constantly learning new things. I'm driven by my
        passion for creating immersive gameplay and bringing ideas to life.
        Please reach out if you would like to learn more!
      </p>
    </section>
  );
};

export default AboutMe;
